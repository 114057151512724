import { logger } from 'shared/utils/logging/Logger';

window.addEventListener('unhandledrejection', e => {
  const error =
    e.reason instanceof Error ? e.reason : new Error(String(e.reason));
  logger.error({
    message: `Unhandled Rejection! ${error.name}: ${error.message}`,
    callStack: error.stack ?? '',
  });
});

window.addEventListener('error', e => {
  const error = e.error instanceof Error ? e.error : new Error(e.message);
  const callStack = `${e.message}\n\tat ${e.filename}:${e.lineno}:${e.colno}`;
  logger.error({
    message: `Uncaught Error! ${error.name}: ${error.message}`,
    callStack,
  });
});

window.logger = logger;

